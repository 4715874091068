<template>
  <!-- 新 - 首页 - 课程详情 -->
  <div class="courseCenter">
    <headPageNew />
    <div class="courseStudy">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          :to="{
            path: '/wrongAuthenticatePage/courseCenter',
            query: { courseTypeId: '' },
          }"
          >全部课程</el-breadcrumb-item
        >
        <el-breadcrumb-item
          v-if="courseTypeName"
          :to="{
            path: '/wrongAuthenticatePage/courseCenter',
            query: { courseTypeId, name: courseTypeName },
          }"
          >{{ courseTypeName }}</el-breadcrumb-item
        >
        <el-breadcrumb-item
          v-if="courseTypeTwoName"
          :to="{
            path: '/wrongAuthenticatePage/courseCenter',
            query: { courseTypeId, name: courseTypeName, courseTypeTwoName },
          }"
          >{{ courseTypeTwoName }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ ruleForm.courseName }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="section--top">
        <div class="section--video">
          <div class="prism-player" id="J_prismPlayer"></div>
          <!-- <script type="text/template" id="endPreviewTemplate">
            <div class="vip_limit_content">
              <div class="vip_limit_wrap">
                <p class="title">试看结束</p>
              </div>
            </div>
          </script> -->
        </div>

        <div class="task-list-wrapper">
          <el-tree
            ref="myTree"
            :data="treeData"
            node-key="courseOutlineId"
            default-expand-all
            v-if="treeData.length"
            highlight-current
            @node-click="handleNodeClick"
          >
            <div class="custom-tree-node" slot-scope="{ node, data }">
              <p class="flexac" v-if="data.level === 1">
                <span
                  :title="data.catalogName"
                  class="ellipsis texthidden"
                  :class="data.nodeClickBoolean ? 'fontColor' : ''"
                  style="margin-right: 3px; width: 260px; opacity: 0.87"
                  >{{ data.catalogName }}</span
                >
                <section style="display:flex;align-items:center;margin-top:10px"> 
                   <span style="color: #fff; opacity: 0.5">{{
                  data.videoDurationStr
                }}  </span>
                <span v-if="data.trySee" class="trySee" style="margin-left:10px">试看</span>
                </section>
               
              </p>
              <template v-else-if="data.level === 2">
                <div style="display: flex; flex-direction: column">
                  <div style="display: flex; align-items: center">
                    <span
                      :title="data.catalogName"
                      class="ellipsis texthidden"
                      :class="data.nodeClickBoolean ? 'fontColor' : ''"
                      style="margin-right: 3px; width: 260px; opacity: 0.87"
                      >{{ data.catalogName }}</span
                    >
                    
                  </div>
                  <section style="display:flex;align-items:center;margin-top:10px"> 
                   <span style="color: #fff; opacity: 0.5;">{{
                  data.videoDurationStr
                }}  </span>
                <span v-if="data.trySee" class="trySee" style="margin-left:10px">试看</span>
                </section>
                </div>
              </template>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="section--bottom">
        <div class="courseInformation">
          <h4>
            {{ ruleForm.courseName
            }}<span>{{
              Number(ruleForm.managePrice) > 0 && ruleForm.freeState == "10"
                ? ruleForm.managePrice + "元"
                : "免费"
            }}</span>
          </h4>
          <span style="font-size: 15px"
            >共{{ ruleForm.coursewareNum }}节
            <span style="margin-left:20px;">{{ ruleForm.courseVideoDurationStr }}</span>
          </span>
        </div>
        <div>
          <el-button @click="contactTeacher" type="primary" style="width: 237px"
            >联系老师</el-button
          >
        </div>
      </div>
    </div>
    <div class="courseContextBox">
      <div class="courseContextDetail">
        <div class="courseContext">
          <h4>课程介绍</h4>
          <div v-if="ruleForm.introduceTemplateType=='10'" v-html="ruleForm.context" class="coursebreak"></div>
          <DetailsTemplate v-if="ruleForm.introduceTemplateType=='20'" :templateData="ruleForm.templateBO" />
        </div>
        <div style="width:337px; background: #fff; height: 1060px;display: flex;flex-direction: column;">
          <h4 style="margin: 0 0 20px 0">相关推荐</h4>
          <div class="courserecommend">
            <div class="courseBox">
              <div
                v-for="(item, index) in courseTjList"
                :key="index"
                class=""
                @click="courseDetail(item)"
              >
                <img :src="item.logo" alt="" />
                <p class="inNovate_subTitle">
                  {{ item.courseName }}
                </p>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    padding-top: 1.5rem;
                  "
                >
                  <div class="courseDetail">
                    <span
                      >章节: <span>共{{ item.coursewareNum }}节</span></span
                    >
                  </div>
                  <div class="courseDetailBtn">
                    <span
                      >{{ tranNumber(item.studyNum || 0, 2) || 0 }}人想学</span
                    >
                    <el-button size="mini" @click="courseDetail(item)"
                      >详情</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomPage />
    <el-dialog
      title="课程合作请加企业微信或拨打联系电话"
      class="popUp"
      width="600px"
      top="10%"
      :visible.sync="contactTeacherDialog"
      :before-close="editPopup"
    >
      <el-divider style="margin: 0"></el-divider>
      <div class="contactTeacherPopup">
         <img src="@/assets/loginIndex/bottom_wxzx.png" alt="" style="width:120px;height:120px"/>
        <p>扫码添加企业微信</p>
        <p style="padding: 30px 0">联系电话: 18601015296</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import headPageNew from "../wrongAuthenticatePage/headPageNew.vue";
import bottomPage from "./bottomPage.vue";
import DetailsTemplate from "@/components/detailsTemplate.vue";
export default {
  name: "wrongAuthenticatePage_courseCenter",
  components: {
    headPageNew,
    bottomPage,
    DetailsTemplate
  },
  data() {
    return {
      courseId: this.$route.query.courseId,
      courseType: this.$route.query.courseType,
      courseTypeId: this.$route.query.courseTypeId,
      courseTypeName: this.$route.query.courseTypeName,
      courseTypeTwoName: this.$route.query.courseTypeTwoName,
      ruleForm: {
        courseCoverUrl:'',
      },
      treeData: [],
      styleType: "",
      //联系老师
      contactTeacherDialog: false,
      courseOutlineId: "",
      videoId: "",
      videoPlayAuth: "",
      trySee: "",
      kpointSource: "",
      courseTjList: [],
    };
  },
  created() {
    this.getCourse(this.courseId);
    this.getTj(this.courseId, this.courseType);
  },
  mounted() {
    if (this.player) {
      this.player.dispose();
      this.player = null;
    }
    this.start(
      this.videoId,
      this.videoPlayAuth,
      this.trySee,
      this.kpointSource
    );
  },
  computed: {},
  methods: {
    //获取课程详细信息
    getCourse(courseId) {
      this.$post("/homepage/detail", { courseId }, 3000, true, 2).then(
        (ret) => {
          let miniCourse = ret.data.miniCourse;
          let miniCourseNodes = ret.data.miniCourseNodes;
          let chapter = ret.data.chapter;
          this.ruleForm = {
            ...this.ruleForm,
            courseVideoDurationStr: ret.data.courseVideoDurationStr,
            courseName: miniCourse.courseName,
            coursewareNum: miniCourse.coursewareNum,
            context: miniCourse.context,
            courseCoverUrl: ret.data.courseCoverUrl,
            miniPrice: miniCourse.miniPrice,
            managePrice: miniCourse.managePrice,
            freeState: miniCourse.freeState,
            introduceTemplateType:miniCourse.introduceTemplateType,
            templateBO:ret.data.templateBO,
          };
          if (chapter) {
            this.styleType = 1;
            miniCourseNodes.map((el) => {
              el.level = 1;
              el.nodeClickBoolean = false;
              el.children.map((els) => {
                els.level = 2;
                els.nodeClickBoolean = false;
                els.kpointSource = els.kpointSource || '10';
              });
              this.treeData = miniCourseNodes;
            });
          } else {
            this.styleType = 2;
            miniCourseNodes.map((el) => {
              let moreCourse = [];
              moreCourse.push({
                ind: new Date().getTime(),
                level: 2,
                catalogName: el.catalogName,
                courseOutlineId: el.courseOutlineId,
                isEdit: "0",
                videoId: el.videoId,
                videoPlayAuth: el.videoPlayAuth,
                section: el.section,
                trySee: el.trySee,
                progress: 100,
                nodeClickBoolean: false,
                kpointSource: el.kpointSource,
                videoDurationStr: el.videoDurationStr,
              });
              this.treeData = [...this.treeData, ...moreCourse];
            });
          }
          this.treeData.map((el) => {
            if (el.children) {
              this.courseOutlineId =
                this.treeData[0].children[0].courseOutlineId;
              this.videoId = this.treeData[0].children[0].videoId;
              this.videoPlayAuth = this.treeData[0].children[0].videoPlayAuth;
              this.trySee = this.treeData[0].children[0].trySee;
              this.kpointSource = this.treeData[0].children[0].kpointSource || '10';
            } else {
              this.courseOutlineId = this.treeData[0].courseOutlineId;
              this.videoId = this.treeData[0].videoId;
              this.videoPlayAuth = this.treeData[0].videoPlayAuth;
              this.trySee = this.treeData[0].trySee;
              this.kpointSource = this.treeData[0].kpointSource || '10';
            }
          });
          this.$nextTick(() => {
            this.$refs.myTree.setCurrentKey(this.courseOutlineId);
            this.start(
              this.videoId,
              this.videoPlayAuth,
              this.trySee,
              this.kpointSource
            );
          });
        }
      );
    },
    //课程节点点击事假
    handleNodeClick(node, data) {
      if (data.nodeClickBoolean) {
        this.$set(node, "nodeClickBoolean", !data.nodeClickBoolean);
        this.$set(data, "nodeClickBoolean", !data.nodeClickBoolean);
      } else {
        this.$set(node, "nodeClickBoolean", !data.nodeClickBoolean);
        this.$set(data, "nodeClickBoolean", !data.nodeClickBoolean);
      }
      if (node.trySee) {
        if (node.videoId) {
          if (this.player) {
            this.player.dispose();
            this.player = null;
          }
          this.start(node.videoId, node.videoPlayAuth, node.trySee,node.kpointSource);
        }
      } else {
        if (this.player) {
          this.player.dispose();
          this.player = null;
        }
        this.start(
          node.videoId,
          node.videoPlayAuth,
          node.trySee,
          node.kpointSource
        );
      }
    },
    start(videoId, videoPlayAuth, trySee, kpointSource) {
      if (trySee) {
        if (kpointSource == "25") {
          this.player = new Aliplayer(
            {
              id: "J_prismPlayer",
              width: "100%",
              height: "100%",
              autoplay: true,
              format: "m3u8",
              encryptType: "1",
              isLive: false,
              rePlay: false,
              playsinline: true,
              preload: false,
              controlBarVisibility: "hover",
              useH5Prism: true,
              //支持播放地址播放,此播放优先级最高
              source: videoPlayAuth,
              //播放方式二：点播用户推荐
              // vid: videoId,
              // playauth: videoPlayAuth,
              components: [
                // {
                //   name: "PreviewVodComponent",
                //   type: AliPlayerComponent.PreviewVodComponent,
                //   args: [180, "#endPreviewTemplate", ""],
                // },
                {
                  name: "RateComponent",
                  type: AliPlayerComponent.RateComponent,
                },
              ],
              skinLayout: [
                // { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                // { name: "H5Loading", align: "cc" },
                { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                { name: "infoDisplay" },
                { name: "tooltip", align: "blabs", x: 0, y: 56 },
                { name: "thumbnail" },
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [
                    { name: "progress", align: "blabs", x: 0, y: 44 },
                    { name: "playButton", align: "tl", x: 15, y: 12 },
                    { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                    {
                      name: "fullScreenButton",
                      align: "tr",
                      x: 10,
                      y: 12,
                    },
                    //   { name: "setting", align: "tr", x: 15, y: 12 },
                    { name: "volume", align: "tr", x: 5, y: 10 },
                  ],
                },
              ],
            },
            function (player) {
              // player.setCover(this.ruleForm.courseCoverUrl);
              var close_btn = document.querySelector(".vip_limit_close_btn");
              close_btn.addEventListener("click", function () {
                /* Call the closePreviewLayer method to close the dialog box */
                player.getComponent("PreviewVodComponent").closePreviewLayer();
              });
            }
          );
        } else {
          this.player = new Aliplayer(
            {
              id: "J_prismPlayer",
              width: "100%",
              height: "100%",
              autoplay: true,
              format: "m3u8",
              encryptType: "1",
              isLive: false,
              rePlay: false,
              playsinline: true,
              preload: false,
              controlBarVisibility: "hover",
              useH5Prism: true,
              //支持播放地址播放,此播放优先级最高
              // source: videoPlayAuth,
              //播放方式二：点播用户推荐
              vid: videoId,
              playauth: videoPlayAuth,
              components: [
                // {
                //   name: "PreviewVodComponent",
                //   type: AliPlayerComponent.PreviewVodComponent,
                //   args: [180, "#endPreviewTemplate", ""],
                // },
                {
                  name: "RateComponent",
                  type: AliPlayerComponent.RateComponent,
                },
              ],
              skinLayout: [
                // { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                // { name: "H5Loading", align: "cc" },
                { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                { name: "infoDisplay" },
                { name: "tooltip", align: "blabs", x: 0, y: 56 },
                { name: "thumbnail" },
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [
                    { name: "progress", align: "blabs", x: 0, y: 44 },
                    { name: "playButton", align: "tl", x: 15, y: 12 },
                    { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                    {
                      name: "fullScreenButton",
                      align: "tr",
                      x: 10,
                      y: 12,
                    },
                    //   { name: "setting", align: "tr", x: 15, y: 12 },
                    { name: "volume", align: "tr", x: 5, y: 10 },
                  ],
                },
              ],
            },
            function (player) {
              // player.setCover(this.ruleForm.courseCoverUrl);
              // var close_btn = document.querySelector(".vip_limit_close_btn");
              // close_btn.addEventListener("click", function () {
              //   /* Call the closePreviewLayer method to close the dialog box */
              //   player.getComponent("PreviewVodComponent").closePreviewLayer();
              // });
            }
          );
        }
      } else {
        if (kpointSource == "25") {
          this.player = new Aliplayer(
            {
              id: "J_prismPlayer",
              width: "100%",
              height: "100%",
              cover: this.ruleForm.courseCoverUrl,
              autoplay: false,
              format: "m3u8",
              encryptType: "1",
              isLive: false,
              rePlay: false,
              playsinline: true,
              preload: false,
              controlBarVisibility: "hover",
              useH5Prism: true,
              //支持播放地址播放,此播放优先级最高
              // source: videoPlayAuth,
              //播放方式二：点播用户推荐
              // vid: videoId,
              // playauth: videoPlayAuth,
              skinLayout: [
                // { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                // { name: "H5Loading", align: "cc" },
                //   { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                //   { name: "infoDisplay" },
                //   { name: "tooltip", align: "blabs", x: 0, y: 56 },
                //   { name: "thumbnail" },
                //   {
                //     name: "controlBar",
                //     align: "blabs",
                //     x: 0,
                //     y: 0,
                //     children: [
                //       { name: "progress", align: "blabs", x: 0, y: 44 },
                //       { name: "playButton", align: "tl", x: 15, y: 12 },
                //       { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                //       {
                //         name: "fullScreenButton",
                //         align: "tr",
                //         x: 10,
                //         y: 12,
                //       },
                //       // { name: "setting", align: "tr", x: 15, y: 12 },
                //       { name: "volume", align: "tr", x: 5, y: 10 },
                //     ],
                //   },
              ],
            },
            function (player) {
              player.setCover(this.ruleForm.courseCoverUrl);
            }
          );
        } else {
           this.player = new Aliplayer(
            {
              id: "J_prismPlayer",
              width: "100%",
              height: "100%",
              cover: this.ruleForm.courseCoverUrl,
              autoplay: false,
              format: "m3u8",
              encryptType: "1",
              isLive: false,
              rePlay: false,
              playsinline: true,
              preload: false,
              controlBarVisibility: "hover",
              useH5Prism: true,
              //支持播放地址播放,此播放优先级最高
              // source: videoPlayAuth,
              //播放方式二：点播用户推荐
              // vid: videoId,
              // playauth: videoPlayAuth,
              skinLayout: [
                // { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                // { name: "H5Loading", align: "cc" },
                //   { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                //   { name: "infoDisplay" },
                //   { name: "tooltip", align: "blabs", x: 0, y: 56 },
                //   { name: "thumbnail" },
                //   {
                //     name: "controlBar",
                //     align: "blabs",
                //     x: 0,
                //     y: 0,
                //     children: [
                //       { name: "progress", align: "blabs", x: 0, y: 44 },
                //       { name: "playButton", align: "tl", x: 15, y: 12 },
                //       { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                //       {
                //         name: "fullScreenButton",
                //         align: "tr",
                //         x: 10,
                //         y: 12,
                //       },
                //       // { name: "setting", align: "tr", x: 15, y: 12 },
                //       { name: "volume", align: "tr", x: 5, y: 10 },
                //     ],
                //   },
              ],
            },
            function (player) {
              player.setCover(this.ruleForm.courseCoverUrl);
            }
          );
        }
      }
    },
    getTj(courseId, courseType) {
      this.$post(
        "/homepage/courseRelevantRecommend",
        { courseId, courseType },
        3000,
        true,
        2
      ).then((res) => {
        this.courseTjList = res.data;
      });
    },
    //联系老师
    contactTeacher() {
      this.contactTeacherDialog = true;
    },
    //详情
    courseDetail(item) {
      this.player.dispose();
      this.player = null;
      this.treeData = [];
      this.courseOutlineId = "";
      this.getCourse(item.courseId);
      //   this.start(this.videoId, this.videoPlayAuth, this.trySee);
      this.getTj(item.courseId, item.courseType);
    },
    //转换10000-万
    tranNumber(num, point) {
      let numStr = num.toString();
      // 一万以内直接返回
      if (numStr.length < 5) {
        return numStr;
      } else if (numStr.length > 4) {
        let decimal = numStr.substring(
          numStr.length - 4,
          numStr.length - 4 + point
        );
        return parseFloat(parseInt(num / 10000) + "." + decimal) + "万";
      }
    },
  },
};
</script>
<style lang="less">
.el-tree-node {
  white-space: none;
}
.vip_limit_content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.vip_limit_content .title {
  font-size: 18px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  width: 100%;
}

.vip_limit_button_box {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.vip_limit_btn {
  display: inline-block;
  min-width: 100px;
  position: relative;
  background: #f60;
  padding: 0 35px;
  margin: 0px 5px 20px 5px;
  border-radius: 38px;
  font-size: 18px;
  line-height: 38px;
  color: #623a0c;
  text-align: center;
  background-image: linear-gradient(
    -135deg,
    #fbe8a8 0%,
    #f8e7ac 15%,
    #e2c078 100%
  );
  cursor: pointer;
}

.vip_limit_close {
  text-align: center;
}
.vip_limit_close span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 36px;
  background: rgba(165, 165, 165, 0.54);
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
}
</style>
<style lang="less" scoped>
.courseCenter {
  margin-top: 62px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 0 !important }
}
.courseStudy {
  width: 1168px;
  margin: 0 auto;
  /deep/ .el-breadcrumb {
    margin: 32px 0 22px 0;
    font-size: 16px;
    color: #666;
  }
}
.section--bottom {
  width: 100%;
  min-height: 72px;
  padding: 15px 23px 15px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .courseInformation {
    h4 {
      color: #fff;
      span {
        color: #ff4f23;
        margin-top: 0;
        margin-left: 15px;
        opacity: 0.87;
      }
    }
    span {
      color: #fff;
      opacity: 0.5;
      margin-top: 15px;
      span {
        opacity: 1;
        margin-top: 0;
      }
    }
  }
}
.section--top {
  display: flex;
  width: 100%;
  font-size: 0;
  .section--video {
    width: 868px;
    position: relative;
    #J_prismPlayer {
      position: absolute;
      /deep/.prism-cover {
        background-size: 100% 100% !important;
      }
    }
  }
  .task-list-wrapper {
    display: inline-block;
    vertical-align: top;
    height: 488px;
    width: 300px;
    background-color: #222;
    font-size: 12px;
    position: relative;
    padding: 20px 0;
    overflow: auto;
    .el-tree {
      height: 100%;
      background: #222;
      color: #fff;
      //   opacity: 0.5;

      /deep/.el-tree-node__content {
        height: 59px;
      }
      /deep/.el-tree-node__content:hover {
        background: #222;
      }
      /deep/.el-tree-node:focus > .el-tree-node__content {
        background-color: #222;
      }
      //   /deep/.el-tree-node {
      //     margin: 10px 0;
      //   }
    }
    /deep/
      .el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content {
      background: #222;
      color: #2878ff;
    }
    .ellipsis {
      font-size: 0.9rem;
    }
    .texthidden {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.courseContextBox {
  background: #f9f9f9;
  padding: 30px 0;
}
.courseContextDetail {
  display: flex;
  width: 1168px;
  margin: 0 auto;
  justify-content: space-between;
  .courseContext {
    width: 811px;
    background: #fff;
    h4 {
      margin-bottom: 20px;
    }
    .coursebreak {
      line-height: 30px;
      word-break: break-all;
      /deep/ img {
        display: block !important;
        margin: auto !important;
        width: 100% !important;
      }
    }
  }
  > div {
    padding: 20px;
  }
}
.trySee {
  width: 46px;
  height: 21px;
  background: rgb(40, 120, 255);
  border-radius: 11px;
  color: rgb(255, 255, 255);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactTeacherPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  p {
    font-size: 18px;
  }
}
.courseCenter {
  .popUp{
    /deep/.el-dialog__wrapper {
      z-index: 9999 !important;
    }
    /deep/.el-dialog {
      background: transparent;
    }
    /deep/.el-dialog__header {
      background: #fff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 20px 20px 10px;
      .el-dialog__title {
        color: #333;
        font-size: 20px;
      }
      .el-dialog__headerbtn {
        top: 38px;
        .el-dialog__close {
          color: #333;
        }
      }
      .el-dialog__headerbtn:focus .el-dialog__close,
      .el-dialog__headerbtn:hover .el-dialog__close {
        color: #333;
      }
    }
    /deep/.el-dialog__body {
      background: #fff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 0 30px;
    }
    .el-divider--horizontal {
      margin: 0 !important;
    }
  }
}
.courserecommend {
  width: 297px;
  height: 100%;
  display: flex;
}
.courseBox {
  display: flex;
  flex-direction: column;
  flex: 1;
  > div {
    border: 1px solid #edf4ff;
    border-radius: 0.3rem;
    // width: calc((100% - 60px) / 4);
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-bottom: 20px;
    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 23px 0px rgba(40, 120, 255, 0.1);
    }
    .courseDetail {
      padding: 1.1rem 0.75rem 0.55rem;
      span {
        color: #666;
      }
    }
    .courseDetailBtn {
      padding: 0 0.75rem 0.55rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #999;
      }
      button {
        background: #fff;
        color: #5393ff;
        border-color: #5393ff;
      }
    }
  }
  img {
    width: 100%;
    border-radius: 0.3rem 0.3rem 0 0;
    height: 158px;
  }
  p {
    text-align: left;
    color: #333;
    font-size: 1rem;
    padding: 0.55rem 0.75rem 0 0.75rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  span {
    font-size: 0.8rem;
  }
}
</style>
