<template>
  <!-- 课程详情模板1 -->
  <div class="detail">
    <!-- 课程简介 -->
    <div class="detail1 publicBg">
      <div class="publicBgIcon detailIcon"></div>
      <div class="publicTitWrap"
        ><div class="publicTit">{{ templateData.introduceTitle }}</div></div
      >
      <div class="imgWrap">
        <img
          style="object-fit: cover;"
          v-for="item in templateData.introducePhotoBOS"
          :src="item.introducePhotoUrl"
        ></img>
      </div>
      <div class="content">
        <div style="white-space: pre-wrap">{{templateData.introduceContext}}</div>
      </div>
    </div>
    <!-- 谁适合学 -->
    <div class="detail2 publicBg">
      <div class="publicBgIcon detailIcon"></div>
      <div class="publicTitWrap"
        ><div class="publicTit">{{ templateData.recommendTitle }}</div></div
      >
      <div class="imgWrap">
        <div v-for="item in templateData.recommendBOS" :key="item.recommendId">
          <img style="object-fit: cover;" :src="item.recommendPhotoUrl"></img>
          <div :title="item.recommendContext" style="white-space: pre-wrap;">{{ item.recommendContext }}</div>
        </div>
      </div>
    </div>
    <!-- 你会收获什么 -->
    <div class="detail3 publicBg">
      <div class="publicTitWrap"
        ><div class="publicTit">{{ templateData.harvestTitle }}</div></div
      >
      <div
        class="txtItem"
        v-for="item in templateData.harvestBOS"
        :key="item.featureId"
        style="white-space: pre-wrap;"
        >{{ item.context }}</div>
    </div>
    <!-- 课程特点 -->
    <div class="detail4 publicBg">
      <div class="publicBgIcon detailIcon"></div>
      <div class="publicTitWrap"
        ><div class="publicTit">{{ templateData.featureTitle }}</div></div
      >
      <div class="txtWrap">
        <div v-for="item in templateData.featureBOS" :key="item.featureId">
          <div class="icon1"></div>
          <div class="icon2"></div>
          <div :title="item.context" style="white-space: pre-wrap;">{{ item.context }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "detailsTemplate",
  props: {
    templateData: {
      //数据
      type: Object,
      require: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style scoped>
.detail {
  background: #f2f6ff;
  padding: 80px 32px 48px;
  position: relative;
  overflow: hidden;
  z-index: 2;
}
/* 公共样式 */
.publicBg {
  background: #fff;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  margin-bottom: 102px;
  overflow: visible;
}
.publicBg:last-child {
  margin-bottom: 0px;
}
.publicBgIcon {
  position: absolute;
  width: 228px;
  height: 228px;
  border-radius: 50%;
  background: linear-gradient(#80b0ff, #dfeaff);
  z-index: -1;
}
.publicTitWrap {
  position: absolute;
  top: -40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.publicTit {
  padding: 22px 40px;
  color: #fff;
  font-size: 24px;
  border-radius: 16px;
  background: linear-gradient(270deg, #c4c9ff 0%, #2878ff 100%);
}
/* 公共样式end */
/* 课程简介 */
.detail1 {
  position: relative;
  padding: 86px 40px 40px;
}
.detail1 .detailIcon {
  top: -80px;
  left: -170px;
}
.detail1 .imgWrap {
  margin-bottom: 25px;
}
.detail1 .imgWrap img {
  margin-right: 30px;
  box-sizing: border-box;
  width: 188px;
  height: 188px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  border: 2px solid #ffffff;
  margin-bottom: 15px;
}
.detail1 .imgWrap img:last-child{
  margin-right: 0;
}
.detail1 .content {
  font-size: 22px;
  color: #666666;
  line-height: 1.8em;
}
/* 谁适合学 */
.detail2 {
  position: relative;
  padding: 86px 40px 40px;
}
.detail2 .detailIcon {
  bottom: -80px;
  right: -170px;
  background: linear-gradient(#dfeaff, #80b0ff);
}
.detail2 .imgWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 25px;
}
.detail2 .imgWrap > div {
  width: 288px;
  margin-bottom: 15px;
}
.detail2 .imgWrap img {
  width: 288px;
  height: 288px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  border: 2px solid #ffffff;
  margin-bottom: 10px;
}
.detail2 .imgWrap > div > div {
  font-size: 22px;
  color: #333333;
  line-height: 1.8em;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* 你会收获什么 */
.detail3 {
  position: relative;
  padding: 86px 40px 40px;
}
.detail3 .txtItem {
  padding: 34px 50px;
  background: #e5eefe;
  font-size: 22px;
  color: #327eff;
  text-align: center;
  border-radius: 60px;
  font-weight: bold;
  margin-bottom: 32px;
}
/* 课程特点 */
.detail4 {
  position: relative;
  padding: 86px 40px 40px;
}
.detail4 .detailIcon {
  top: -80px;
  left: -170px;
  background: linear-gradient(#dfeaff, #80b0ff);
}
.detail4 .txtWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;
}
.detail4 .txtWrap > div {
  width: 292px;
  background: #e6efff;
  box-sizing: border-box;
  padding: 60px 24px 42px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  font-size: 22px;
  color: #333333;
  line-height: 1.6em;
  margin-bottom: 32px;
}
.detail4 .txtWrap > div > div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
.detail4 .txtWrap > div .icon1 {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgb(255, 255, 255, 0.5);
}
.detail4 .txtWrap > div .icon2 {
  position: absolute;
  right: -60px;
  bottom: -60px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: radial-gradient(#80b0ff, #e6efff);
}
</style>